<template>
  <div class="page-payment">
    <p class="amount-title">请选择还款期数</p>
    <div class="checkbox">
      <van-checkbox-group v-model="checkeds" ref="checkboxGroup">
        <van-cell-group inset>
          <van-cell
            v-for="(item, index) in loanresult.planList"
            clickable
            :key="item"
            :title="`第${item.planIndex}期`"
            @click="toggle(index)"
          >
            <span v-if="item.periodStatusDes == '已逾期'" class="status1"
            >(已逾期)</span
            >
            <span v-if="item.periodStatusDes == '本期'" class="status2"
            >(本 期)</span
            >
            <span v-if="item.periodStatusDes == '未到期'" class="status3"
            >(未到期)</span
            >
            <span class="moneyNum">{{
                $filters.formatMoney(item.planAmt, 2)
              }}</span>
            <template #right-icon>
              <van-checkbox
                shape="square"
                :name="item"
                :ref="(el) => (checkboxRefs[index] = el)"
                @click.stop
                :disabled="item.canBack != 'YES'"
              />
            </template>
            <!-- <template #right-icon>
              <van-checkbox
                disabled
                shape="square"
                :name="item"
                :ref="(el) => (checkboxRefs[index] = el)"
                @click.stop
              />
            </template> -->
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </div>
    <div class="bottom">
      <p class="bottom-left">
        <span class="oneText">还款金额</span>
        <span class="twoText">￥{{ $filters.formatMoney(allPrice, 2) }}</span>
      </p>
      <p class="onsure" @click="handleSubmit">确认</p>
    </div>
  </div>
</template>

<script>
import {toRefs, reactive, onMounted, computed, onBeforeUpdate, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {Toast} from 'vant'
import {routerTag} from '../../utils/index'
import API from '../../server/api'
import {Jump} from '@/utils/routh';

export default {
  setup() {
    const state = reactive({
      list: ['1', '2', '3', '4'],
      checkboxRefs: [],
      checkeds: [],
      allPrice: 0,
      periodList: [],
      loanresult: {},
      backResult: null,
      checked: true,
      loanNo: ''
    })
    const checkboxGroup = ref(null)

    // 初始化信息
    const init = async () => {
      try{
        const res = await API.getloanInfo({loanNo: state.loanNo})
        if(res.success) state.loanresult = res.data;
      }catch (err){
        Toast(err.message)
        console.log(err.message)
      }
    }
    onMounted(async () => {
      state.loanNo = localStorage.getItem('loanNos')
      state.checkeds = []
      await init()
      document.title = '确认还款结果'
    })

    const toggle = (index) => {
      state.checkboxRefs.value[index].toggle()
    }

    onBeforeUpdate(() => {
      state.checkboxRefs.value = []
      state.allPrice = 0
      state.periodList = []
      state.checkeds.forEach((item) => {
        // 如果点击的是本期的话，就把上边的全选（跳过禁止选中的）
        if (item.periodStatusDes === '本期') {
          checkboxGroup.value.toggleAll({
            checked: state.checked,
            skipDisabled: true
          })
          state.allPrice = state.checkeds.length * item.planAmt
          for (let i = state.checkeds[0].planIndex; i <= item.planIndex; i++) {
            state.periodList.push(i)
          }
        } else {
          state.allPrice += item.planAmt
          state.periodList.push(item.planIndex)
          state.periodList = state.periodList.sort(function (a, b) {
            return a - b
          })
        }
      })
    })

    // 点击确认
    const handleSubmit = async () => {
      if (state.allPrice === 0) {
        Toast('请选择还款期数！')
        return
      }

      try{
         const res = await API.backFee({
          isSettle: false,
          loanNo: state.loanNo,
          periodList: state.periodList
        })
        if(res.success){
          state.backResult = res.data;
          localStorage.setItem('backResult', JSON.stringify(state.backResult))
          Jump(`/pay`)
        }
      }catch (err){
        Toast(err.message)
        console.log(err.message)
      }

    }
    return {
      ...toRefs(state),
      toggle,
      handleSubmit,
      checkboxGroup
    }
  }
}
</script>
<style lang="less" src="./index.less" scoped></style>
<style>
.page-payment .van-cell__value {
  flex: none;
}

</style>
